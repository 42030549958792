import i18n from '@/vendors/i18n'

export default {
  name: 'custom_header_muco60',
  settings: {
    unavailable: true
  },
  components: [
    {
      path: 'modules/pages/SectionTitle',
      content: {
        label: i18n.getLocalesTranslations('literal.logo')
      }
    },
    {
      path: 'modules/pages/SectionImageUpload',
      model: 'logo',
      settings: {
        hide_label: true,
        size: { width: 9999, height: 34 }
      },
      content: {
        label: i18n.getLocalesTranslations('literal.image')
      }
    },
    {
      path: 'modules/pages/SectionDivider',
      style: {
        divider: ['separator','separator__size--full', 'bg__color--vdw']
      }
    },
    {
      path: 'modules/pages/SectionTitle',
      content: {
        label: i18n.getLocalesTranslations('literal.navigation')
      }
    },
    {
      path: 'modules/pages/SectionRepeater',
      model: 'navigation',
      settings: {
        hide_label: true
      },
      content: {
        wrapper_title: 'link.text',
        default_wrapper_title: i18n.getLocalesTranslations('literal.link'),
        btn_label: i18n.getLocalesTranslations('literal.add_new_link')
      },
      components: [
        {
          path: 'modules/pages/SectionButton',
          model: 'link'
        },
        {
          path: 'modules/pages/SectionSelect',
          model: 'hash',
          value: null,
          content: {
            label: { 'fr-FR': 'Ancre', 'nl-NL': 'Anker', 'en-GB': 'Anchor' }
          },
          settings: {
            hide_label: true,
            options: [
              {
                label: i18n.getLocalesTranslations('literal.select_an_option'),
                value: ''
              },
              {
                label: { 'fr-FR': 'Graffiti', 'nl-NL': 'Graffiti', 'en-GB': 'Graffiti' },
                value: '#artwork'
              }
            ]
          }
        }
      ]
    },
    {
      path: 'modules/pages/SectionDivider',
      style: {
        divider: ['separator','separator__size--full', 'bg__color--vdw', 'margin__t--36']
      }
    },
    {
      path: 'modules/pages/SectionText',
      style: {
        text: ['color--lg']
      },
      content: {
        text: i18n.getLocalesTranslations('literal.language_switch')
      }
    },
    {
      path: 'modules/pages/SectionText',
      style: {
        text: ['color--lg']
      },
      content: {
        text: i18n.getLocalesTranslations('literal.login_register_and_profile')
      }
    },
    {
      path: 'modules/pages/SectionDivider',
      style: {
        divider: ['separator','separator__size--full', 'bg__color--vdw', 'margin__t--36']
      }
    },
    {
      path: 'modules/pages/SectionTitle',
      content: {
        label: i18n.getLocalesTranslations('literal.call_to_action')
      }
    },
    {
      path: 'modules/pages/SectionButton',
      model: 'cta',
      settings: {
        hide_label: true
      }
    }
  ]
}