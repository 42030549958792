import i18n from '@/vendors/i18n'
import { metrics_type_options, metrics_sporting_options } from '@/configurations/general/components-default-data'

export default [
  {
    name: 'homepage_muco60_settings',
    settings: {
      icon: 'form',
      unavailable: true,
      show_delete_option: false,
      show_drag_option: false
    },
    components: [
      {
        path: 'modules/pages/SectionSelectCampaigns',
        model: 'campaign_id',
        content: {
          label: i18n.getLocalesTranslations('literal.campaign')
        },
        settings: {
          max: 1
        }
      },
      {
        path: 'modules/pages/SectionInputNumber',
        model: 'limit_of_projects',
        content: {
          label: i18n.getLocalesTranslations('literal.limit_number_of_projects')
        }
      }
    ],
    content: {
      title: i18n.getLocalesTranslations('literal.settings')
    }
  },
  {
    name: 'homepage_muco60_banner',
    settings: {
      icon: 'banner',
      unavailable: true,
      show_delete_option: false,
      show_drag_option: false
    },
    components: [
      {
        path: 'modules/pages/SectionInputText',
        model: 'title',
        content: {
          label: i18n.getLocalesTranslations('literal.title')
        }
      },
      {
        path: 'modules/pages/SectionImageUpload',
        content: {
          label: i18n.getLocalesTranslations('literal.image')
        },
        model: 'image',
        settings: {
          crop: false,
          size: { width: 1500, height: 600 }
        }
      }
    ],
    content: {
      title: i18n.getLocalesTranslations('literal.banner')
    }
  },
  {
    name: 'homepage_muco60_stats',
    settings: {
      icon: 'timer',
      unavailable: true,
      show_delete_option: false,
      show_drag_option: false
    },
    components: [
      {
        path: 'modules/pages/SectionContainer',
        model: 'query',
        content: {
          default_wrapper_title: i18n.getLocalesTranslations('literal.settings')
        },
        components: [
          {
            path: 'modules/pages/SectionSelectCampaigns',
            model: 'campaign_id',
            content: {
              label: i18n.getLocalesTranslations('literal.show_metrics_from_these_campaigns')
            }
          },
          {
            path: 'modules/pages/SectionSelectForms',
            model: 'procedure_id',
            content: {
              label: i18n.getLocalesTranslations('literal.show_metrics_from_these_forms')
            }
          },
          {
            path: 'modules/pages/SectionSelectBenefitings',
            model: 'benefiting_id',
            content: {
              label: i18n.getLocalesTranslations('literal.show_metrics_from_these_beneficiaries')
            }
          }
        ]
      },
      {
        path: 'modules/pages/SectionRepeater',
        model: 'metrics',
        content: {
          wrapper_title: 'text',
          btn_label: i18n.getLocalesTranslations('literal.add_metric'),
          default_wrapper_title: i18n.getLocalesTranslations('literal.choose_a_metric')
        },
        settings: {
          hide_label: true
        },
        components: [
          {
            path: 'modules/pages/SectionSelect',
            model: 'type',
            value: 'contributors_count',
            settings: {
              options: [...metrics_type_options, { value: 'custom', label: i18n.getLocalesTranslations('literal.custom') }, { value: 'm2', label: { 'fr-FR': 'm2 restants', 'nl-NL': 'Resterende m2', 'en-GB': 'Remaining m2' } }]
            },
            content: {
              label: i18n.getLocalesTranslations('literal.metric')
            }
          },
          {
            path: 'modules/pages/SectionInputDate',
            model: 'date',
            content: {
              label: i18n.getLocalesTranslations('literal.date')
            },
            conditions: [
              {
                logic_operator: 'and',
                model: 'type',
                value: 'date',
                comparison_operator: 'eq'
              }
            ]
          },
          {
            path: 'modules/pages/SectionSelect',
            model: 'sporting_statistics',
            value: 'total_distance',
            settings: {
              options: metrics_sporting_options
            },
            content: {
              label: i18n.getLocalesTranslations('literal.sporting_statistics')
            },
            conditions: [
              {
                logic_operator: 'and',
                model: 'type',
                value: 'sporting_statistics',
                comparison_operator: 'eq'
              }
            ]
          },
          {
            path: 'modules/pages/SectionInputText',
            model: 'value',
            content: {
              label: i18n.getLocalesTranslations('literal.value')
            },
            conditions: [
              {
                logic_operator: 'and',
                model: 'type',
                value: 'custom',
                comparison_operator: 'eq'
              }
            ]
          },
          {
            path: 'modules/pages/SectionInputText',
            model: 'text'
          }
        ]
      }
    ],
    content: {
      title: i18n.getLocalesTranslations('literal.counter')
    }
  },
  {
    name: 'homepage_muco60_about',
    settings: {
      icon: 'text_editor',
      unavailable: true,
      show_delete_option: false,
      show_drag_option: false
    },
    components: [
      {
        path: 'modules/pages/SectionInputText',
        model: 'title',
        content: {
          label: i18n.getLocalesTranslations('literal.title')
        }
      },
      {
        path: 'modules/pages/SectionTextEditor',
        model: 'text',
        settings: {
          configuration: ['bold', 'italic', 'underline', 'link', 'text_color']
        },
        content: {
          label: i18n.getLocalesTranslations('literal.text')
        }
      }
    ],
    content: {
      title: i18n.getLocalesTranslations('literal.about')
    }
  },
  {
    name: 'homepage_muco60_artwork',
    settings: {
      icon: 'layout',
      unavailable: true,
      show_delete_option: false,
      show_drag_option: false
    },
    components: [
      {
        path: 'modules/pages/SectionInputText',
        model: 'title',
        content: {
          label: i18n.getLocalesTranslations('literal.title')
        }
      },
      {
        path: 'modules/pages/SectionTextEditor',
        model: 'text',
        settings: {
          configuration: ['bold', 'italic', 'underline', 'link', 'text_color']
        },
        content: {
          label: i18n.getLocalesTranslations('literal.text')
        }
      },
      {
        path: 'modules/pages/SectionImageUpload',
        content: {
          label: i18n.getLocalesTranslations('literal.image')
        },
        model: 'image',
        settings: {
          crop: false,
          size: { width: 1280, height: 9999 }
        }
      },
      {
        path: 'modules/pages/SectionTextEditor',
        model: 'text_2',
        settings: {
          configuration: ['bold', 'italic', 'underline', 'link', 'text_color']
        },
        content: {
          label: i18n.getLocalesTranslations('literal.text')
        }
      },
      {
        path: 'modules/pages/SectionButton',
        model: 'cta',
        content: {
          label: i18n.getLocalesTranslations('literal.call_to_action')
        }
      },
      {
        path: 'modules/pages/SectionContainer',
        model: 'modal',
        content: {
          wrapper_title: 'title',
          default_wrapper_title: i18n.getLocalesTranslations('literal.project')
        },
        components: [
          {
            path: 'modules/pages/SectionInputTextarea',
            model: 'title',
            content: {
              label: i18n.getLocalesTranslations('literal.title')
            },
            settings: {
              variables: ['firstname', 'lastname']
            }
          },
          {
            path: 'modules/pages/SectionTextEditor',
            model: 'text',
            settings: {
              configuration: ['bold', 'italic', 'underline', 'link', 'text_color']
            },
            content: {
              label: i18n.getLocalesTranslations('literal.text')
            }
          },
          {
            path: 'modules/pages/SectionInputText',
            model: 'cta',
            content: {
              label: i18n.getLocalesTranslations('literal.call_to_action')
            }
          }
        ]
      }
    ],
    content: {
      title: { 'fr-FR': 'Graffiti', 'nl-NL': 'Graffiti', 'en-GB': 'Graffiti' }
    }
  },
  {
    name: 'homepage_muco60_how_it_works',
    settings: {
      icon: 'message_cog',
      unavailable: true,
      show_delete_option: false,
      show_drag_option: false
    },
    components: [
      {
        path: 'modules/pages/SectionRepeater',
        model: 'steps',
        settings: {
          hide_label: true
        },
        content: {
          wrapper_title: 'title',
          label: i18n.getLocalesTranslations('literal.steps'),
          btn_label: i18n.getLocalesTranslations('literal.add_step')
        },
        components: [
          {
            path: 'modules/pages/SectionImageUpload',
            model: 'icon',
            settings: {
              size: { width: 9999, height: 50 }
            },
            content: {
              label: i18n.getLocalesTranslations('literal.icon')
            }
          },
          {
            path: 'modules/pages/SectionInputText',
            model: 'title',
            content: {
              label: i18n.getLocalesTranslations('literal.title')
            }
          },
          {
            path: 'modules/pages/SectionTextEditor',
            model: 'text',
            settings: {
              configuration: ['bold', 'italic', 'underline']
            },
            content: {
              label: i18n.getLocalesTranslations('literal.text')
            }
          }
        ]
      },
      {
        path: 'modules/pages/SectionTextEditor',
        model: 'text',
        settings: {
          configuration: ['bold', 'italic', 'underline', 'link', 'text_color']
        },
        content: {
          label: i18n.getLocalesTranslations('literal.text')
        }
      },
      {
        path: 'modules/pages/SectionButton',
        model: 'cta',
        content: {
          label: i18n.getLocalesTranslations('literal.call_to_action')
        }
      }
    ],
    content: {
      title: i18n.getLocalesTranslations('literal.how_it_works')
    }
  },
  {
    name: 'homepage_muco60_artists',
    settings: {
      icon: 'id_card',
      unavailable: true,
      show_delete_option: false,
      show_drag_option: false
    },
    components: [
      {
        path: 'modules/pages/SectionInputText',
        model: 'title',
        content: {
          label: i18n.getLocalesTranslations('literal.title')
        }
      },
      {
        path: 'modules/pages/SectionContainer',
        model: 'media',
        content: {
          default_wrapper_title: i18n.getLocalesTranslations('literal.media')
        },
        components: [
          {
            path: 'modules/pages/SectionSelect',
            model: 'type',
            value: 'image',
            content: {
              label: i18n.getLocalesTranslations('literal.type')
            },
            settings: {
              options: [
                {
                  label: i18n.t('literal.image'),
                  value: 'image'
                },
                {
                  label: i18n.t('literal.video'),
                  value: 'video'
                }
              ]
            }
          },
          {
            path: 'modules/pages/SectionImageUpload',
            model: 'image',
            settings: {
              size: { width: 700, height: 466 }
            },
            content: {
              label: i18n.getLocalesTranslations('literal.image')
            },
            conditions: [
              {
                logic_operator: 'and',
                model: 'type',
                value: 'image',
                comparison_operator: 'eq'
              }
            ]
          },
          {
            path: 'modules/pages/SectionInputUrl',
            model: 'video',
            content: {
              label: i18n.getLocalesTranslations('literal.video')
            },
            validations: ['url', 'validVideoUrl'],
            conditions: [
              {
                logic_operator: 'and',
                model: 'type',
                value: 'video',
                comparison_operator: 'eq'
              }
            ]
          }
        ]
      },
      {
        path: 'modules/pages/SectionRepeater',
        model: 'items',
        settings: {
          hide_label: true
        },
        content: {
          wrapper_title: 'title',
          default_wrapper_title: { 'fr-FR': 'Artiste', 'nl-NL': 'Kunstenaar', 'en-GB': 'Artist' }
        },
        components: [
          {
            path: 'modules/pages/SectionInputText',
            model: 'title',
            content: {
              label: i18n.getLocalesTranslations('literal.title')
            }
          },
          {
            path: 'modules/pages/SectionTextEditor',
            model: 'text',
            settings: {
              configuration: ['bold', 'italic', 'underline']
            },
            content: {
              label: i18n.getLocalesTranslations('literal.text')
            }
          },
          {
            path: 'modules/pages/SectionButton',
            model: 'link',
            content: {
              label: i18n.getLocalesTranslations('literal.link')
            }
          }
        ]
      },
      {
        path: 'modules/pages/SectionButton',
        model: 'cta',
        content: {
          label: i18n.getLocalesTranslations('literal.call_to_action')
        }
      }
    ],
    content: {
      title: { 'fr-FR': 'Artistes', 'nl-NL': 'Kunstenaars', 'en-GB': 'Artists' }
    }
  },
  {
    name: 'homepage_muco60_testimonials',
    settings: {
      icon: 'quote',
      unavailable: true,
      show_delete_option: false,
      show_drag_option: false
    },
    components: [
      {
        path: 'modules/pages/SectionInputText',
        model: 'title',
        content: {
          label: i18n.getLocalesTranslations('literal.title')
        }
      },
      {
        path: 'modules/pages/SectionRepeater',
        model: 'quotes',
        settings: {
          hide_label: true
        },
        content: {
          default_wrapper_title: i18n.getLocalesTranslations('literal.quote'),
          btn_label: i18n.getLocalesTranslations('literal.add_new_element')
        },
        components: [
          {
            path: 'modules/pages/SectionImageUpload',
            model: 'avatar',
            settings: {
              crop: true,
              size: { width: 148, height: 148 }
            }
          },
          {
            path: 'modules/pages/SectionTextEditor',
            model: 'quote',
            settings: {
              configuration: ['bold', 'italic', 'underline', 'link', 'text_color']
            },
            content: {
              label: i18n.getLocalesTranslations('literal.text')
            }
          }
        ]
      },
      {
        path: 'modules/pages/SectionButton',
        model: 'cta',
        content: {
          label: i18n.getLocalesTranslations('literal.call_to_action')
        }
      }
    ],
    content: {
      title: { 'fr-FR': 'Témoignages', 'nl-NL': 'Getuigenissen', 'en-GB': 'Testimonials' }
    }
  },
  {
    name: 'homepage_muco60_campaign',
    settings: {
      icon: 'flag',
      unavailable: true,
      show_delete_option: false,
      show_drag_option: false
    },
    components: [
      {
        path: 'modules/pages/SectionInputText',
        model: 'title',
        content: {
          label: i18n.getLocalesTranslations('literal.title')
        }
      },
      {
        path: 'modules/pages/SectionTextEditor',
        model: 'text',
        settings: {
          configuration: ['bold', 'italic', 'underline', 'link', 'text_color']
        },
        content: {
          label: i18n.getLocalesTranslations('literal.text')
        }
      },
      {
        path: 'modules/pages/SectionImageUpload',
        content: {
          label: i18n.getLocalesTranslations('literal.image')
        },
        model: 'image',
        settings: {
          crop: true,
          size: { width: 624, height: 452 }
        }
      },
      {
        path: 'modules/pages/SectionButton',
        model: 'cta',
        content: {
          label: i18n.getLocalesTranslations('literal.call_to_action')
        }
      }
    ],
    content: {
      title: i18n.getLocalesTranslations('literal.campaign')
    }
  },
  {
    name: 'homepage_muco60_actions',
    settings: {
      icon: 'markbook',
      unavailable: true,
      show_delete_option: false,
      show_drag_option: false
    },
    components: [
      {
        path: 'modules/pages/SectionInputText',
        model: 'title',
        content: {
          label: i18n.getLocalesTranslations('literal.title')
        }
      },
      {
        path: 'modules/pages/SectionRepeater',
        model: 'projects',
        settings: {
          hide_label: true
        },
        content: {
          default_wrapper_title: i18n.getLocalesTranslations('literal.project'),
          wrapper_title: 'title',
          btn_label: i18n.getLocalesTranslations('literal.add_new_element')
        },
        components: [
          {
            path: 'modules/pages/SectionImageUpload',
            model: 'image',
            settings: {
              crop: true,
              size: { width: 407, height: 452 }
            }
          },
          {
            path: 'modules/pages/SectionInputText',
            model: 'title',
            content: {
              label: i18n.getLocalesTranslations('literal.title')
            }
          },
          {
            path: 'modules/pages/SectionInputText',
            model: 'name',
            content: {
              label: i18n.getLocalesTranslations('literal.name')
            }
          },
          {
            path: 'modules/pages/SectionInputText',
            model: 'city',
            content: {
              label: i18n.getLocalesTranslations('literal.city')
            }
          }
        ]
      }
    ],
    content: {
      title: i18n.getLocalesTranslations('literal.actions')
    }
  },
  {
    name: 'homepage_muco60_making_of',
    settings: {
      icon: 'video',
      unavailable: true,
      show_delete_option: false,
      show_drag_option: false
    },
    components: [
      {
        path: 'modules/pages/SectionInputText',
        model: 'title',
        content: {
          label: i18n.getLocalesTranslations('literal.title')
        }
      },
      {
        path: 'modules/pages/SectionSelect',
        model: 'type',
        value: 'images',
        content: {
          label: i18n.getLocalesTranslations('literal.type')
        },
        settings: {
          options: [
            {
              label: i18n.t('literal.images'),
              value: 'images'
            },
            {
              label: i18n.t('literal.video'),
              value: 'video'
            }
          ]
        }
      },
      {
        path: 'modules/pages/SectionRepeater',
        model: 'images',
        settings: {
          hide_label: true
        },
        content: {
          wrapper_title: 'link',
          btn_label: i18n.getLocalesTranslations('literal.add_new_image')
        },
        conditions: [
          {
            logic_operator: 'and',
            model: 'type',
            value: 'images',
            comparison_operator: 'eq'
          }
        ],
        components: [
          {
            path: 'modules/pages/SectionImageUpload',
            model: 'image',
            settings: {
              crop: true,
              size: { width: 407, height: 451 }
            }
          }
        ]
      },
      {
        path: 'modules/pages/SectionInputUrl',
        model: 'video',
        content: {
          label: i18n.getLocalesTranslations('literal.video')
        },
        validations: ['url', 'validVideoUrl'],
        conditions: [
          {
            logic_operator: 'and',
            model: 'type',
            value: 'video',
            comparison_operator: 'eq'
          }
        ]
      },
      {
        path: 'modules/pages/SectionTextEditor',
        model: 'text',
        settings: {
          configuration: ['bold', 'italic', 'underline', 'link', 'text_color']
        },
        content: {
          label: i18n.getLocalesTranslations('literal.text')
        }
      }
    ],
    content: {
      title: { 'fr-FR': 'Making of', 'nl-NL': 'Making of', 'en-GB': 'Making of' }
    }
  },
  {
    name: 'homepage_muco60_partners',
    settings: {
      icon: 'sponsor',
      unavailable: true,
      show_delete_option: false,
      show_drag_option: false
    },
    components: [
      {
        path: 'modules/pages/SectionInputText',
        model: 'title',
        content: {
          label: i18n.getLocalesTranslations('literal.title')
        }
      },
      {
        path: 'modules/pages/SectionTextEditor',
        model: 'text',
        settings: {
          configuration: ['bold', 'italic', 'underline', 'link', 'text_color']
        },
        content: {
          label: i18n.getLocalesTranslations('literal.text')
        }
      },
      {
        path: 'modules/pages/SectionRepeater',
        model: 'sponsors',
        settings: {
          hide_label: true
        },
        content: {
          wrapper_title: 'link',
          btn_label: i18n.getLocalesTranslations('literal.add_new_sponsor')
        },
        components: [
          {
            path: 'modules/pages/SectionImageUpload',
            model: 'logo',
            settings: {
              crop: false,
              size: { width: 9999, height: 180 }
            }
          },
          {
            path: 'modules/pages/SectionInputText',
            model: 'name',
            content: {
              label: i18n.getLocalesTranslations('literal.name')
            }
          },
          {
            path: 'modules/pages/SectionInputUrl',
            model: 'link'
          }
        ]
      }
    ],
    content: {
      title: i18n.getLocalesTranslations('literal.sponsors')
    }
  }
]