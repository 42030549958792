import i18n from '@/vendors/i18n'

export default {
  name: 'faq',
  settings: {
    icon: 'question'
  },
  components: [
    {
      path: 'molecules/ModuleInputTextEditor',
      model: 'attributes.text',
      content: {
        label: i18n.getLocalesTranslations('literal.text')
      }
    },
    {
      path: 'molecules/ModuleRepeater',
      model: 'attributes.questions',
      settings: {
        layout: 'block'
      },
      content: {
        label: i18n.getLocalesTranslations('literal.questions'),
        wrapper_title: 'title',
        btn: i18n.getLocalesTranslations('literal.add_question'),
        default_wrapper_title: i18n.getLocalesTranslations('literal.your_question')
      },
      components: [
        {
          path: 'molecules/ModuleInputText',
          model: 'title',
          content: {
            label: i18n.getLocalesTranslations('literal.question')
          }
        },
        {
          path: 'molecules/ModuleInputTextEditor',
          model: 'text',
          settings: {
            configuration: ['bold', 'italic', 'underline', 'list_ul', 'list_ol', 'link', 'text_color', 'image', 'video', 'code']
          },
          content: {
            label: i18n.getLocalesTranslations('literal.answer')
          }
        }
      ]
    }
  ],
  style: {
    background: {
      color: null,
      type: 'color'
    },
    text: {
      color: null
    }
  },
  content: {
    title: i18n.getLocalesTranslations('literal.faq')
  }
}
