import i18n from '@/vendors/i18n'

export default {
  name: 'custom_footer_muco60',
  settings: {
    unavailable: true
  },
  components: [
    {
      path: 'modules/pages/SectionImageUpload',
      model: 'background',
      settings: {
        crop: false,
        size: { width: 351, height: 272 }
      },
      content: {
        label: i18n.getLocalesTranslations('literal.background')
      }
    },
    {
      path: 'modules/pages/SectionContainer',
      model: 'column_1',
      content: {
        default_wrapper_title: i18n.getLocalesTranslations('literal.informations')
      },
      components: [
        {
          path: 'modules/pages/SectionContainer',
          model: 'logo',
          content: {
            default_wrapper_title: i18n.getLocalesTranslations('literal.logo')
          },
          components: [
            {
              path: 'modules/pages/SectionImageUpload',
              model: 'image',
              settings: {
                hide_label: true,
                size: { width: 9999, height: 42 }
              },
              content: {
                label: i18n.getLocalesTranslations('literal.logo')
              }
            },
            {
              path: 'modules/pages/SectionInputUrl',
              model: 'url',
              content: {
                label: i18n.getLocalesTranslations('literal.url')
              },
              validations: ['url']
            }
          ]
        },
        {
          path: 'modules/pages/SectionTextEditor',
          model: 'text',
          settings: {
            configuration: ['bold', 'italic', 'underline', 'link', 'text_color']
          },
          content: {
            label: i18n.getLocalesTranslations('literal.text')
          }
        },
        {
          path: 'modules/pages/SectionDivider',
          style: {
            divider: ['separator','separator__size--full', 'bg__color--vdw', 'margin__t--36']
          }
        },
        {
          path: 'modules/pages/SectionTitle',
          content: {
            label: i18n.getLocalesTranslations('literal.social_networks')
          }
        }
      ]
    },
    {
      path: 'modules/pages/SectionContainer',
      model: 'column_2',
      content: {
        default_wrapper_title: i18n.getLocalesTranslations('literal.your_organisation')
      },
      components: [
        {
          path: 'modules/pages/SectionInputText',
          model: 'title',
          content: {
            label: i18n.getLocalesTranslations('literal.title')
          }
        },
        {
          path: 'modules/pages/SectionInputTextarea',
          model: 'address',
          content: {
            label: i18n.getLocalesTranslations('literal.address')
          }
        }
      ]
    },
    {
      path: 'modules/pages/SectionContainer',
      model: 'column_3',
      content: {
        default_wrapper_title: i18n.getLocalesTranslations('literal.contact_us')
      },
      components: [
        {
          path: 'modules/pages/SectionInputText',
          model: 'title',
          content: {
            label: i18n.getLocalesTranslations('literal.title')
          }
        },
        {
          path: 'modules/pages/SectionContainer',
          model: 'contact',
          content: {
            default_wrapper_title: i18n.getLocalesTranslations('literal.contact')
          },
          components: [
            {
              path: 'modules/pages/SectionInputText',
              model: 'phone',
              content: {
                label: i18n.getLocalesTranslations('literal.phone')
              }
            },
            {
              path: 'modules/pages/SectionInputText',
              model: 'email',
              content: {
                label: i18n.getLocalesTranslations('literal.email')
              }
            }
          ]
        }
      ]
    },
    {
      path: 'modules/pages/SectionContainer',
      model: 'column_4',
      content: {
        default_wrapper_title: i18n.getLocalesTranslations('literal.financials')
      },
      components: [
        {
          path: 'modules/pages/SectionInputText',
          model: 'title',
          content: {
            label: i18n.getLocalesTranslations('literal.title')
          }
        },
        {
          path: 'modules/pages/SectionTextEditor',
          model: 'bank_account',
          content: {
            label: i18n.getLocalesTranslations('literal.your_bank_accounts')
          }
        }
      ]
    },
    {
      path: 'modules/pages/SectionDivider',
      style: {
        divider: ['separator','separator__size--full', 'bg__color--vdw']
      }
    },
    {
      path: 'modules/pages/SectionTitle',
      content: {
        label: i18n.getLocalesTranslations('literal.navigation')
      }
    },
    {
      path: 'modules/pages/SectionRepeater',
      model: 'navigation',
      content: {
        wrapper_title: 'link.text',
        default_wrapper_title: i18n.getLocalesTranslations('literal.link'),
        btn_label: i18n.getLocalesTranslations('literal.add_new_link')
      },
      settings: {
        hide_label: true
      },
      components: [
        {
          path: 'modules/pages/SectionSelect',
          model: 'type',
          value: 'link',
          content: {
            label: i18n.getLocalesTranslations('literal.type')
          },
          settings: {
            options: [
              {
                label: i18n.t('literal.link'),
                value: 'link'
              },
              {
                label: i18n.t('literal.text'),
                value: 'text'
              }
            ]
          }
        },
        {
          path: 'modules/pages/SectionButton',
          model: 'link',
          settings: {
            hide_label: true,
          },
          conditions: [
            {
              logic_operator: 'and',
              model: 'type',
              value: 'link',
              comparison_operator: 'eq'
            }
          ]
        },
        {
          path: 'modules/pages/SectionInputText',
          model: 'text',
          content: {
            label: i18n.getLocalesTranslations('literal.text')
          },
          conditions: [
            {
              logic_operator: 'and',
              model: 'type',
              value: 'text',
              comparison_operator: 'eq'
            }
          ]
        }
      ]
    }
  ]
}