import { computed, watch, ref, toValue } from 'vue'
import { useNotificationStore } from '@/stores/NotificationStore'
import { getStringifyQuery, toCamelCase } from '@/utils/utils'
import { get as _get, cloneDeep as _cloneDeep } from 'lodash'

export default function gettersStore ({ store }) {
  const { postSideNotification } = useNotificationStore()

  const handleError = error => {
    if (![401, 403].includes(error?.status))
      _get(error, 'data.errors', []).forEach(text => postSideNotification({ text, type: 'error' }))

    return error
  }

  store.$getItemFromItems = ({ stored_key, resource, key = 'id', id, query, dependencies = [], fetch }) => {
    const state = store.$getItems({ resource, query, dependencies, fetch })
    const item = computed(() => _get(state, `${toCamelCase(resource)}.value`, []).find(item => item[key] === toValue(id)))

    return {
      [stored_key]: item,
      loader: state.loader,
      error: state.error
    }
  }

  store.$getItems = ({ resource, query, reload = false, dependencies = [], fetch }) => {
    const key = computed(() => getStringifyQuery({ query: toValue(query), default_value: 'default' }))
    const items = computed(() => _get(store.$state, `${resource}.['${key.value}'].items`, []))
    const pagination = computed(() => _get(store.$state, `${resource}.['${key.value}'].pagination`, {}))
    const loader = computed(() => _get(store.$state, `${resource}.['${key.value}'].loader`, false))
    const error = computed(() => _get(store.$state, `${resource}.['${key.value}'].error`, {}))
    const timestamp = computed(() => _get(store.$state, `${resource}.['${key.value}'].timestamp`))
    const hasDependencies = computed(() => !dependencies.length || dependencies.every(item => toValue(item)))

    watch([key, hasDependencies], () => {
      if (hasDependencies.value && (!timestamp.value || reload) && fetch) fetch(query).catch(handleError)
    }, { immediate: true })

    return {
      [toCamelCase(resource)]: items,
      pagination,
      loader,
      error
    }
  }

  store.$getItem = ({ resource, key, fetch }) => {
    const id = computed(() => toValue(key))
    const path = computed(() => id.value ? `${resource}.${id.value}` : resource)
    const item = computed(() => _get(store.$state, `${path.value}.item`, null))
    const loader = computed(() => _get(store.$state, `${path.value}.loader`, false))
    const error = computed(() => _get(store.$state, `${path.value}.error`, {}))
    const cloned_item = ref(_cloneDeep(item.value))

    watch(item, item => cloned_item.value = _cloneDeep(item))
    watch(id, () => {
      if (!item.value) fetch(id.value).catch(handleError)
    }, { immediate: true })

    return {
      [toCamelCase(resource)]: item,
      [toCamelCase(`cloned_${resource}`)]: cloned_item,
      loader,
      error
    }
  }
}